<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="area_id">
        <area-select
          style="width: 180px"
          @changeArea="changeArea"
        ></area-select>
      </el-form-item>
      <el-form-item label="" prop="hangye">
        <el-select
          v-model="form.hangye"
          clearable
          placeholder="行业"
          style="width: 120px"
        >
          <el-option
            v-for="(i, idx) in hangyeList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.vest_in"
          clearable
          style="width: 120px"
          placeholder="推广人员"
        >
          <el-option
            v-for="(i, idx) in tuiguangList"
            :key="idx"
            :value="i.id"
            :label="i.username"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
    </el-form>
    <div v-loading="loading" class="content">
      <div class="e">
        <div class="l">
          <div ref="zhexian" style="width: 100%; height: 380px"></div>
        </div>
        <div class="r">
          <div ref="zhuzhuang" style="width: 100%; height: 380px"></div>
        </div>
      </div>
      <div class="bottom-form">
        <el-form
          ref="form"
          :model="bottomForm"
          inline
          disabled
          label-position="right"
          label-width="120px"
        >
          <el-form-item
            v-for="(item, idx) in formList"
            :key="idx"
            :label="item.label"
            prop=""
          >
            <el-input v-model="bottomForm[item.prop]"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
  import AreaSelect from '@/baseComponents/areaSelect'
  import * as echarts from 'echarts'
  import { postAction } from '@/api/Employee'

  export default {
    name: 'UserDetail',
    components: { AreaSelect },
    props: {
      hangyeList: {
        type: Array,
        default: () => [],
      },
      tuiguangList: {
        type: Array,
        default: () => [],
      },
      dataType: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        e: null,
        e2: null,
        form: {
          vest_in: '', //:归属人id
          start_time: '', //,:开始时间 如2021-8-1
          end_time: '', //:结束时间 如2021-8-31
          area_id: '', //:地区ID
          hangye_id: '', //:行业ID
        },
        bottomForm: {},
        loading: false,
        time: [],
        list: [],
        formList: [
          {
            label: '新增注册用户数',
            prop: 'dealer_reg_new',
          },
          {
            label: '注册用户总数',
            prop: 'dealer_reg_sum',
          },
          {
            label: '活跃经销商家数',
            prop: 'dealer_live',
          },
          {
            label: '网销单数',
            prop: 'net_num',
          },
          {
            label: '在途预单',
            prop: 'order_num',
          },

          {
            label: '新增正式用户',
            prop: 'dealer_new',
          },
          {
            label: '正式用户总数',
            prop: 'dealer_sum',
          },

          // {
          //   label: '员工总数',
          //   prop: 'user_sum',
          // },

          {
            label: '活跃端口',
            prop: 'user_live',
          },

          {
            label: '网销单金额',
            prop: 'net_money',
          },

          {
            label: '在途金额',
            prop: 'order_money',
          },
          {
            label: '销售单数',
            prop: 'base_num',
          },
          {
            label: '销售单金额',
            prop: 'base_money',
          },
          {
            label: '预存款协议',
            prop: 'deposit_num',
          },
          {
            label: '预存货协议',
            prop: 'goods_num',
          },
          {
            label: '付费陈列活动',
            prop: 'display_num',
          },
          {
            label: '付费陈列协议',
            prop: 'display_money',
          },

          {
            label: '预存款金额',
            prop: 'deposit_money',
          },

          {
            label: '预存货金额',
            prop: 'goods_money',
          },

          // {
          //   label: '活跃家数占比',
          //   prop: 'bfb1',
          // },
          // {
          //   label: '活跃端口占比',
          //   prop: 'bfb2',
          // },
        ],
      }
    },
    computed: {},
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      console.log('2')
    },
    mounted() {
      this.fetchData()
      this.initEchar()
    },
    methods: {
      async fetchData() {
        this.loading = true

        let { data } = await postAction(
          '/superAdmin/statistics/user-summary',
          this.form
        )
        let echarData = data.list

        console.log('d', data)
        let eOpt = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            data: ['新增注册', '新增正式'],
          },
          xAxis: [
            {
              type: 'category',
              data: echarData.day,
              axisPointer: {
                type: 'shadow',
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: '新增注册',
              axisLabel: {
                formatter: '{value}',
              },
            },
            {
              type: 'value',
              name: '新增正式',
              axisLabel: {
                formatter: '{value}',
              },
            },
          ],
          series: [
            {
              name: '新增注册',
              type: 'bar',
              data: echarData.dealer_reg_new,
            },
            {
              name: '新增正式',
              type: 'line',
              yAxisIndex: 1,
              data: echarData.dealer_new,
            },
          ],
        }

        let eOpt2 = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            data: ['销售单数量', '销售单金额'],
          },
          xAxis: [
            {
              type: 'category',
              data: echarData.day,
              axisPointer: {
                type: 'shadow',
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: '销售单数量',
            },
            {
              type: 'value',
              name: '销售单金额',
            },
          ],
          series: [
            {
              name: '销售单数量',
              type: 'line',
              data: echarData.base_num,
            },
            {
              name: '销售单金额',
              type: 'bar',
              yAxisIndex: 1,
              data: echarData.base_money,
            },
          ],
        }
        this.e.setOption(eOpt)
        this.e2.setOption(eOpt2)

        this.bottomForm = data.rows
        this.loading = false
      },
      initEchar() {
        this.e = echarts.init(this.$refs.zhexian)
        this.e2 = echarts.init(this.$refs.zhuzhuang)
      },
      changeArea(v) {
        this.form.area_id = v
      },
      handleQuery() {
        this.form.pageNo = 1
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .e {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    .l,
    .r {
      width: 50%;
      box-sizing: border-box;
    }
  }
</style>
