<template>
  <!-- NAME[epic=admin] 经营统计 -->
  <div class="orderTest-container">
    <el-button-group style="margin: 0 0 10px 0">
      <el-button
        v-for="(item, index) in nav"
        :key="index"
        :type="form.type == index + 1 ? 'primary' : ''"
        @click="form.type = index + 1"
      >
        {{ item }}
      </el-button>
    </el-button-group>
    <keep-alive>
      <components
        :is="currentCom"
        :hangye-list="hangyeList"
        :tuiguang-list="tuiguangList"
        :data-type="dataType"
      ></components>
    </keep-alive>
  </div>
</template>
<script>
  import _ from 'lodash'
  import SignDetail from './components/signDetail'
  import UserDetail from './components/userDetail'
  import UserDetail2 from './components/userDetail2'
  import {
    hangyeSelectList,
    guishuSelectList,
  } from '@/api/admin/userManagement.js'
  export default {
    name: 'OperationStatistics',
    components: { SignDetail, UserDetail, UserDetail2 },
    data() {
      return {
        form: {
          type: 1, //1签约概况2用户概况3用户明细
        },
        nav: ['签约概况', '用户概况', '用户明细'],

        checkList: [
          '订单号',
          '企业名称',
          '联系人',
          '手机',
          '到期日期',
          '端口数',
          '网销门店上限',
          '归属',
          '类型',
          '金额',
          '下单日期',
          '状态',
        ],
        columns: [
          {
            order: 1,
            label: '订单号',
            prop: '',
            width: '',
          },
          {
            order: 2,
            label: '企业名称',
            prop: '',
            width: '',
          },
          {
            order: 3,
            label: '联系人',
            prop: '',
            width: '',
          },
          {
            order: 4,
            label: '手机',
            prop: '',
            width: '',
          },
          {
            order: 5,
            label: '到期日期',
            prop: '',
            width: '',
          },
          {
            order: 6,
            label: '端口数',
            prop: '',
            width: '',
          },
          {
            order: 7,
            label: '网销门店上限',
            prop: '',
            width: '',
          },
          {
            order: 8,
            label: '归属',
            prop: '',
            width: '',
          },
          {
            order: 9,
            label: '类型',
            prop: '',
            width: '',
          },
          {
            order: 10,
            label: '金额',
            prop: '',
            width: '',
          },
          {
            order: 11,
            label: '下单日期',
            prop: '',
            width: '',
          },
          {
            order: 12,
            label: '状态',
            prop: '',
            width: '',
          },
        ],
        hangyeList: [],
        tuiguangList: [],
        dataType: [
          {
            id: '',
            name: '',
          },
          {
            id: '',
            name: '',
          },
        ],
      }
    },
    computed: {
      currentCom() {
        if (this.form.type == 1) {
          return 'signDetail'
        } else if (this.form.type == 2) {
          return 'userDetail'
        } else {
          return 'userDetail2'
        }
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {
      this.initSelect()
    },
    mounted() {},
    methods: {
      async initSelect() {
        let { data } = await hangyeSelectList()
        this.hangyeList = data
        let res2 = await guishuSelectList()
        this.tuiguangList = res2.data
      },
    },
  }
</script>
<style lang="scss" scoped></style>
