<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="area_id">
        <area-select
          style="width: 180px"
          @changeArea="changeArea"
        ></area-select>
      </el-form-item>
      <el-form-item label="" prop="hangye">
        <el-select
          v-model="form.hangye"
          clearable
          placeholder="行业"
          style="width: 120px"
        >
          <el-option
            v-for="(i, idx) in hangyeList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.vest_in"
          clearable
          style="width: 120px"
          placeholder="推广人员"
        >
          <el-option
            v-for="(i, idx) in tuiguangList"
            :key="idx"
            :value="i.id"
            :label="i.username"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.teach_user"
          clearable
          style="width: 120px"
          placeholder="培训人员"
        >
          <el-option
            v-for="(i, idx) in tuiguangList"
            :key="idx"
            :value="i.id"
            :label="i.username"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.order_type"
          clearable
          style="width: 140px"
          placeholder="购买项目"
        >
          <el-option
            v-for="(i, idx) in buyItem"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input v-model="form.keyword" placeholder="用户名称,ID"></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      show-summary
      :summary-method="summaryMethod"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import AreaSelect from '@/baseComponents/areaSelect'
  import { postAction } from '@/api/Employee'

  export default {
    name: 'UserDetail2',
    components: { AreaSelect },
    props: {
      hangyeList: {
        type: Array,
        default: () => [],
      },
      tuiguangList: {
        type: Array,
        default: () => [],
      },
      dataType: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        form: {
          pageSize: 10, //:
          pageNo: 1, //:
          vest_in: '', //:归属人id
          start_time: '', //,:开始时间 如2021-8-1
          end_time: '', //:结束时间 如2021-8-31
          area_id: '', //:地区ID
          hangye_id: '', //:行业ID
        },
        loading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        time: [],
        list: [],
        totalAll: {},
        // 购买项目购买项目  如1,2,3     1签约2加端口3续期4网销升级5培训
        buyItem: [
          {
            id: '1',
            name: '签约',
          },
          {
            id: '2',
            name: '加端口',
          },
          {
            id: '3',
            name: '续期',
          },
          {
            id: '4',
            name: '网销升级',
          },
          {
            id: '5',
            name: '培训',
          },
        ],

        columns: [
          {
            label: '用户名称',
            prop: 'dealer_name',
            width: '180',
          },
          {
            label: '开通端口',
            prop: 'ktdk',
            width: '',
          },
          {
            label: '活跃',
            prop: 'hydk',
            width: '',
          },
          {
            label: '下单客户',
            prop: 'khxd',
            width: '',
          },
          {
            label: '下单占比',
            prop: 'bfb',
            width: '',
          },
          {
            label: '网销下单',
            prop: 'net_num',
            width: '',
          },
          {
            label: '网销金额',
            prop: 'net_money',
            width: '',
          },
          {
            label: '在途预单',
            prop: 'order_num',
            width: '',
          },
          {
            label: '在途金额',
            prop: 'order_money',
            width: '',
          },
          {
            label: '销售单',
            prop: 'base_num',
            width: '',
          },
          {
            label: '销售单金额',
            prop: 'base_money',
            width: '',
          },
          {
            label: '陈列活动',
            prop: 'display1_count',
            width: '',
          },
          {
            label: '陈列协议',
            prop: 'display2_count',
            width: '',
          },
          {
            label: '预存款活动',
            prop: 'deposit1_count',
            width: '',
          },
          {
            label: '预存款协议',
            prop: 'deposit2_count',
            width: '',
          },
          {
            label: '预存货活动',
            prop: 'goods1_count',
            width: '',
          },
          {
            label: '预存货协议',
            prop: 'goods2_count',
            width: '',
          },
          {
            label: '购买项目',
            prop: 'admin1_count',
            width: '',
          },
          {
            label: '付费合计',
            prop: 'admin2_total_amount',
            width: '',
          },
        ],
      }
    },
    computed: {},
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await postAction(
          '/superAdmin/statistics/user-detail',
          this.form
        )
        this.total = totalCount
        this.totalAll = data.zong
        this.list = data.rows
        this.loading = false
      },
      changeArea(v) {
        this.form.area_id = v
      },
      handleQuery() {
        this.form.pageNo = 1
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      summaryMethod(param) {
        let sums = ['总计']
        // sums[] = this.totalAll.account_num// "端口总数",
        sums[2] = this.totalAll.ktdk // "开通数",
        sums[3] = this.totalAll.hydk // "活跃数",
        // sums[4] = this.totalAll.khzs// "客户总数",
        sums[4] = this.totalAll.khxd // "下单的客户数",
        sums[6] = this.totalAll.net_num // "网销单数",
        sums[7] = this.totalAll.net_money // "网销金额",
        sums[8] = this.totalAll.order_num // "在途单数",
        sums[9] = this.totalAll.order_money // "在途金额",
        sums[10] = this.totalAll.base_num // "销售单数",
        sums[11] = this.totalAll.base_money // "销售金额",
        sums[12] = this.totalAll.display1_count // "陈列活动个数",
        sums[13] = this.totalAll.display2_count // "陈列协议数",
        sums[14] = this.totalAll.deposit1_count // "预存款活动数",
        sums[15] = this.totalAll.deposit2_count // "预存款协议数",
        sums[16] = this.totalAll.goods1_count // "预存货活动数",
        sums[17] = this.totalAll.goods2_count // "预存货协议数",
        sums[19] = this.totalAll.admin2_total_amount // "付费总金额"
        return sums
      },
    },
  }
</script>
<style lang="scss" scoped></style>
