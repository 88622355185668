<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="area_id">
        <area-select
          style="width: 180px"
          @changeArea="changeArea"
        ></area-select>
      </el-form-item>
      <el-form-item label="" prop="hangye">
        <el-select
          v-model="form.hangye"
          clearable
          placeholder="行业"
          style="width: 120px"
        >
          <el-option
            v-for="(i, idx) in hangyeList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.vest_in"
          clearable
          style="width: 120px"
          placeholder="推广人员"
        >
          <el-option
            v-for="(i, idx) in tuiguangList"
            :key="idx"
            :value="i.id"
            :label="i.username"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
    </el-form>
    <div v-loading="loading" class="content">
      <div class="l">
        <div class="echars-wrap">
          <!-- 柱状图 -->
          <div
            id="zhuzhuangtu"
            ref="zhuzhuangtu"
            style="width: 100%; height: 380px"
          ></div>
          <div id="staff" ref="staff" style="width: 100%; height: 380px"></div>
          <div id="area" ref="area" style="width: 100%; height: 380px"></div>
        </div>
      </div>
      <!-- 表格 -->
      <div class="r">
        <div class="t-wrap">
          <el-table stripe :data="list1" height="350" style="margin-top: 15px">
            <el-table-column
              v-for="(item, tableIndex) in cloumns1"
              :key="tableIndex"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              align="center"
            ></el-table-column>
          </el-table>
          <el-table stripe :data="list2" height="350" style="margin-top: 25px">
            <el-table-column
              v-for="(item, tableIndex) in cloumns2"
              :key="tableIndex"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              align="center"
            ></el-table-column>
          </el-table>
          <el-table stripe :data="list3" height="350" style="margin-top: 25px">
            <el-table-column
              v-for="(item, tableIndex) in cloumns3"
              :key="tableIndex"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              align="center"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import * as echarts from 'echarts'
  import { postAction } from '@/api/Employee'
  import AreaSelect from '@/baseComponents/areaSelect'
  export default {
    name: 'SignDetail',
    components: { AreaSelect },
    props: {
      hangyeList: {
        type: Array,
        default: () => [],
      },
      tuiguangList: {
        type: Array,
        default: () => [],
      },
      dataType: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        loading: false,
        time: [],
        list: [],
        // hangyeList: [],
        // tuiguangList: [],
        e: null,
        e2: null,
        e3: null,
        form: {
          pageSize: -1, //:
          pageNo: 1, //:
          vest_in: '', //:归属人id
          start_time: '', //,:开始时间 如2021-8-1
          end_time: '', //:结束时间 如2021-8-31
          area_id: '', //:地区ID
          hangye_id: '', //:行业ID
        },
        list1: [],
        list2: [],
        list3: [],
        cloumns1: [
          {
            label: '日期',
            prop: 'days',
          },
          {
            label: '签约家数',
            prop: 'count',
          },
          {
            label: '成交金额',
            prop: 'total_amount',
          },
          {
            label: '占比',
            prop: 'bfb',
          },
        ],
        cloumns2: [
          {
            label: '姓名',
            prop: 'username',
          },
          {
            label: '签约家数',
            prop: 'count',
          },
          {
            label: '成交金额',
            prop: 'total_amount',
          },
          {
            label: '占比',
            prop: 'bfb',
          },
        ],
        cloumns3: [
          {
            label: '省',
            prop: 'province',
          },
          {
            label: '市',
            prop: 'city',
          },
          {
            label: '签约家数',
            prop: 'count',
          },
          {
            label: '成交金额',
            prop: 'total_amount',
          },
          {
            label: '占比',
            prop: 'bfb',
          },
        ],
      }
    },
    computed: {},
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      console.log('1')
      this.fetchData()
    },
    mounted() {
      // this.initSelect()
      this.initEchar()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await postAction(
          '/superAdmin/statistics/order-summary',
          this.form
        )
        let tableData = data.rows
        let echarData = data.list

        this.list1 = tableData.order
        this.list2 = tableData.staff
        this.list3 = tableData.area
        // 第一个柱状图
        let eOpt = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            data: ['成交家数', '成交金额'],
          },
          xAxis: [
            {
              type: 'category',
              data: echarData.day,
              axisPointer: {
                type: 'shadow',
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: '成交家数',
              axisLabel: {
                formatter: '{value} 家',
              },
            },
            {
              type: 'value',
              name: '成交金额',
              axisLabel: {
                formatter: '{value} 元',
              },
            },
          ],
          series: [
            {
              name: '成交家数',
              type: 'bar',
              data: echarData.order.count,
            },
            {
              name: '成交金额',
              type: 'line',
              yAxisIndex: 1,
              data: echarData.order.money,
            },
          ],
        }

        // 第二个echart
        let userName = []
        let userDataTotal = []
        data.rows.staff.forEach((i) => {
          userName.push(i.username)
          userDataTotal.push(i.total_amount)
        })
        let eOpt2 = {
          // grid: {
          //   left: '3%',
          //   right: '4%',
          //   bottom: '3%',
          //   containLabel: true,
          // },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          xAxis: {
            type: 'value',

            // boundaryGap: [0, 0.01],
          },
          yAxis: {
            type: 'category',
            data: userName,
            inverse: true,
          },
          series: [
            {
              realtimeSort: true,
              name: '成交金额',
              type: 'bar',
              data: userDataTotal,
              label: {
                show: true,
                position: 'right',
                valueAnimation: true,
              },
            },
          ],
        }

        // 第三个  饼状图
        let pingData = JSON.parse(echarData.area)
        console.log('pingData', pingData)
        let eOpt3 = {
          // title: {
          //   text: 'Referer of a Website',
          //   subtext: 'Fake Data',
          //   left: 'center',
          // },
          tooltip: {
            trigger: 'item',
          },
          legend: {
            // orient: 'vertical',
            left: 'left',
          },
          series: [
            {
              name: '成交金额',
              type: 'pie',
              radius: '50%',
              data: pingData,
              // emphasis: {
              //   itemStyle: {
              //     shadowBlur: 10,
              //     shadowOffsetX: 0,
              //     shadowColor: 'rgba(0, 0, 0, 0.5)',
              //   },
              // },
            },
          ],
        }

        this.e.setOption(eOpt)
        this.e2.setOption(eOpt2)
        this.e3.setOption(eOpt3)

        this.loading = false
      },
      initEchar() {
        this.e = echarts.init(this.$refs.zhuzhuangtu)
        this.e2 = echarts.init(this.$refs.staff)
        this.e3 = echarts.init(this.$refs.area)
      },
      // async initSelect() {
      //   let { data } = await hangyeSelectList()
      //   this.hangyeList = data
      //   let res = await areaSelectList()
      //   this.areaSelect = res.data
      //   let res2 = await guishuSelectList()
      //   this.tuiguangList = res2.data
      // },
      changeArea(v) {
        this.form.area_id = v
      },
      handleQuery() {
        this.form.pageNo = 1
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;

    .l,
    .r {
      flex-wrap: nowrap;
      width: 50%;
      display: flex;
      flex-direction: column;
    }
  }
</style>
