var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-button-group",
        { staticStyle: { margin: "0 0 10px 0" } },
        _vm._l(_vm.nav, function (item, index) {
          return _c(
            "el-button",
            {
              key: index,
              attrs: { type: _vm.form.type == index + 1 ? "primary" : "" },
              on: {
                click: function ($event) {
                  _vm.form.type = index + 1
                },
              },
            },
            [_vm._v(" " + _vm._s(item) + " ")]
          )
        }),
        1
      ),
      _c(
        "keep-alive",
        [
          _c(_vm.currentCom, {
            tag: "components",
            attrs: {
              "hangye-list": _vm.hangyeList,
              "tuiguang-list": _vm.tuiguangList,
              "data-type": _vm.dataType,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }